import React from "react"
import Layout from "../components/layout"
import './termsAndPolicy.scss'


const Terms = () => {

  return (
    <Layout>
      <div className="wrapper-section-terms-policy">
        <h1>TERMOS DE USO DE SERVIÇO ("TERMOS")</h1>
        <p><i>Data da última atualização: 18 de novembro de 2020</i></p>
        <p>Aqui, quando falarmos <strong>THEIA</strong> (ou "Theia"), estaremos nos referindo tanto à
            Alessa Brasil Tecnologia Ltda., sediada no município de São Paulo/SP, inscrita no CNPJ/MF
            sob o número 33.855.879/0001-02, como também a outras empresas, ou sociedades, afiliadas
            à esta empresa. Também, poderá ser referente aos serviços prestados, a serem descritos a seguir.
        </p>
        <p>A <strong>THEIA</strong> fornece aos usuários acesso a produtos e serviços próprios ou de terceiros
            ("Serviços"), por meio de aplicativos em dispositivos móveis, website, entre outros
            ("Plataforma Theia", "Plataforma" ou "Theia" mesmo), em acordo com os presentes Termos e
            com a Política de Privacidade ("Política").
        </p>
        <p>Recomendamos veementemente a leitura com muita atenção destes Termos, pois, afinal, este
            documento pode ser considerado o acordo firmado entre a Theia e você, e aqui estão descritas
            as responsabilidades de cada um em relação aos Serviços e à Plataforma. Apesar de longo, nos
            esforçamos para fazer o texto dos Termos ser o mais claro possível. Ressaltamos que o aceite
            dos Termos e da Política são condições necessárias para você poder utilizar nossa Plataforma
            e nossos Serviços. Em outras palavras,
            <strong>
            caso você não concorde integralmente (sem exceções)
            com o descrito nos Termos e na Política, você não poderá utilizar nossos Serviços.
            </strong>
        </p>
        <p>
            Você pode acessar nossa Política de Privacidade clicando&nbsp;
            <a
            href="https://theia.com.br/policy"
            target="_blank"
            className="cookies-link"
            >aqui</a>.
        </p>
        <p>
            Caso tenha quaisquer dúvidas, reclamações ou elogios a respeito do nosso trabalho, não hesite
            em mandar um e-mail para o endereço ola@theia.com.br.
        </p>
        <p>
            Na tentativa de facilitar a compreensão destes Termos para o leitor, os separamos em tópicos,
            conforme se seguem:‍
        </p>
        <ol className="list-number">
            <li>A Theia é um Plano de Saúde?;</li>
            <li>O que você precisa para se tornar cliente da Theia?;</li>
            <li>O que você TEM que concordar antes de se tornar nosso cliente?;</li>
            <li>Quem são os profissionais especializados que prestam serviço para a Theia?;</li>
            <li>Quais as responsabilidades da Theia?;</li>
            <li>Acesso e funcionalidades;</li>
            <li>Planos e formas de pagamento;</li>
            <li>Como funciona a exclusão de uma conta?;</li>
            <li>Propriedade intelectual;</li>
            <li>Proteção de Dados Pessoais;</li>
            <li>O que a Theia vai fazer quando quiser alterar ou atualizar estes Termos?;</li>
            <li>O que mais?;</li>
            <li>E se, mesmo assim, você continuar com dúvidas?</li>
        </ol>
        <p>Completada a listagem, vamos aos detalhamentos:</p>
        <dl>
            <dt>A Theia é um Plano de Saúde?;</dt>
            <dd>
            <p>
                Não, a Theia <b>NÃO</b> é um plano de saúde e nem uma operadora de planos
                de saúde, e não tem qualquer intuito ou objetivo de substituir, parcial ou integralmente, os planos
                privados de assistência à saúde atualmente oferecidos no mercado, conforme regulamentação da Agência
                Nacional de Saúde Suplementar (ANS).
            </p>
            <p>
                A Theia é, na verdade, focada em <b>tecnologia</b> e no uso de soluções de tecnologia para prover uma
                intermediação, entre prestadores de serviços de saúde e bem-estar e usuários, mediante uma Plataforma
                segura, fácil de utilizar, acolhedora e confiável, a qual propicia a possibilidade de conversas com
                profissionais especializados em diversas áreas de atuação, esclarecendo dúvidas relativas à gestação,
                amamentação, nutrição, desenvolvimento, saúde e bem-estar de bebês, crianças, gestantes, mães e pais.
                Também, é possível, na Plataforma, realizar agendamentos para atendimentos presenciais com
                profissionais,
                cumprindo, então, o papel da Theia como intermediadora dos Serviços.
            </p>
            <p>
                Por eventualmente gerar essa confusão com sermos uma empresa de saúde, e por haver profissionais de
                saúde que prestam serviços para a Plataforma, é importantíssimo deixar claro uma coisa (pedimos
                desculpas pela repetição):
            </p>
            <p><u>
                Por sermos uma empresa de <b>Tecnologia</b>, não somos: plano de saúde, operadora de plano de saúde, pronto
                socorro, hospital, unidade de atenção de saúde, unidade de pronto atendimento, unidade de atendimento
                ambulatorial, ou outras nomenclaturas. Assim, <b>situações de emergência ou urgência devem ser sempre
                encaminhadas ao
                serviço de pronto atendimento da respectiva cidade ou região do usuário, não sendo a plataforma Theia
                indicada
                para estas situações</b>.
            </u></p>
            </dd>

            <dt>O que você precisa para se tornar cliente da Theia?;</dt>
            <dd>
            <p>
                Bom, basicamente, você precisa declarar que tem mais de 18 (dezoito) anos de idade completos e
                declarar que leu, compreendeu e aceitou, na totalidade, os presentes Termos e a Política de Privacidade,
                e ser parte do nosso público-alvo.
            </p>
            <p>
                Em outras palavras, a respeito do público-alvo, como a Theia é uma empresa ainda em crescimento e
                queremos garantir que prestaremos o melhor serviço possível para nossos usuários, nem todos os serviços
                que
                intermediamos podem ser úteis a toda a população. Assim, nos limitados o direito de poder restringir o
                acesso à Plataforma apenas a pessoas que se enquadrem no nosso público-alvo (inclusive filtrando a
                localização geográfica, que pode ser específica para garantir uma melhor qualidade de Serviços), o qual,
                inclusive, pode variar com o tempo.
            </p>
            <p>
                Além disso, ainda com o intuito de garantir a melhor experiência aos usuários, a Theia reserva-se no
                direito de limitar a quantidade de usuários cadastrados na plataforma. O acesso poderá ser limitado,
                desta
                forma, por uma lista de espera para cadastros de novos usuários, a qual será reduzida conforme a
                disponibilidade de acessos à Plataforma. Não há ingerência, prazo definido ou outro critério (além de o
                usuário ser público
                alvo) para priorizar a autorização de acesso. Caso a Theia decida implementar uma política de convites,
                as
                pessoas que receberem o convite por usuários já cadastrados na plataforma também estarão sujeitas à
                lista de
                espera.
            </p>
            </dd>

            <dt>O que você TEM que concordar antes de se tornar nosso cliente?;</dt>
            <dd>
            <p>
                Já dissemos - e vamos repetir mais algumas vezes - que você deve concordar com tudo o que está nos
                presentes Termos e na Política (lembrando que você pode acessar a Política de Privacidade&nbsp;
                <a
                href="https://theia.com.br/policy"
                target="_blank"
                className="cookies-link"
                >aqui</a>&nbsp;
                mas, nesta pergunta específica, vamos detalhar alguns pontos que merecem
                atenção e
                detalhamento, para deixar tudo claro e você não ficar com dúvidas. Antes de se tornar nosso cliente,
                você deve compreender e concordar que:
            </p>
            <ol className="sub-list-roman">
                <li>
                <b>Situações de emergência ou urgência devem ser sempre encaminhadas ao serviço de pronto
                    atendimento da respectiva cidade ou região do usuário, não sendo a plataforma Theia indicada para
                    estas situações;</b>
                </li>
                <li>
                Não faltará com respeito, e nem praticará atos lesivos, como assédio, discriminação, preconceito,
                agressão a ninguém relacionado direta ou indiretamente à Plataforma Theia ou não (ou seja, se formos
                informados sobre atos lesivos, mesmo que não relacionados à Theia, podemos tomar as providências que
                nos
                couberem);
                </li>
                <li>
                Você tem mais de 18 (dezoito) anos completos na data de criação da sua conta;
                </li>
                <li>
                Todas as informações que você informar à Theia são verídicas, sem nenhuma exceção, e, caso haja
                alterações nos seus dados pessoais, os atualizará prontamente;
                </li>
                <li>
                Não divulgará, não emprestará, venderá, cederá ou dividirá com terceiros seus dados de acesso, mesmo
                que sejam pessoas da sua família ou pessoas próximas a você;
                </li>
                <li>
                Restringirá o acesso a terceiros aos seus dispositivos em que for possível acessar a Plataforma;
                </li>
                <li>
                Você é responsável por tudo o que ocorrer a partir de sua conta na Plataforma;
                </li>
                <li>
                A Theia poderá auditar as informações cadastrais solicitadas e solicitar o envio de informações
                complementares ou documentos comprobatórios das informações fornecidas;
                </li>
                <li>
                A Plataforma Theia é uma plataforma online e que, para o devido funcionamento do aplicativo, o
                dispositivo para acesso à plataforma e a conectividade à rede de dados deverão atender os requisitos
                mínimos de configuração solicitados;
                </li>
                <li>
                A Plataforma Theia é uma plataforma online e que, justamente por ser assim, está sujeita a momentos ou
                períodos de instabilidade, dificuldade de acesso ou mesmo indisponibilidade, por motivos que fogem do
                nosso alcance;
                </li>
                <li>
                Não enviará ou transmitirá informações ou dados não relacionados a seu histórico de saúde para
                ambientes externos à Plataforma;
                </li>
                <li>
                Não violará a legislação vigente, princípios morais e éticos, e nem o presente nos Termos de Uso e
                Política de Privacidade (afinal, aceitá-los é pré-requisito para cadastro, mas respeitá-los é
                pré-requisito para continuar utilizando a Plataforma);
                </li>
                <li>
                Comunicará imediatamente a Theia no caso de perda, usurpação, vazamento de dados ou esquecimento de
                seus dados da conta pelo e-mail suporte@theia.com.br;
                </li>
                <li>
                Poderemos te contatar, enviar mensagens, <i>e-mails</i>, notificações e lembretes de forma eletrônica, mesmo
                que fora da Plataforma;
                </li>
                <li>
                Tem ciência de que é terminantemente proibido, sob pena das medidas legais cabíveis, qualquer
                movimento que venha a ser relacionado com engenharia reversa, descompilamento, acesso a código fonte
                ou qualquer tecnologia da Theia, na tentativa de consultar, utilizar, vender, realizar cessão ou
                licença, seja
                para benefício próprio ou de terceiros, quando relacionados a quaisquer informações ou tecnologias de
                propriedade intelectual
                da Theia (ver tópico específico de Propriedade Intelectual);
                </li>
                <li>
                Não tentará se passar por outros usuários, nem tentará simular, falsificar, omitir informações,
                endereços de IP, <i>e-mails</i>, na tentativa de obter para si ou para terceiros vantagem, ou causar dano à
                Theia ou a terceiros;
                </li>
                <li>
                Não contatará os especialistas da Theia por outras plataformas, sem o intermédio da Theia, a fim de
                tentar negociar valores diferentes dos praticados na Plataforma ou com o intuito de lesar a Theia ou
                terceiros.
                </li>
            </ol>
            <p>
                Assim, você compreende, concorda e consente que, na violação de quaisquer destes itens (ou de qualquer
                disposto nos Termos e na Política), a Theia não será a responsável, bem como não seremos
                responsabilizados pelos prejuízos, ônus ou danos que decorrerem disso. Muito pelo contrário, se houver
                alguma dessas violações ocorridas da sua parte, teremos que tomar as medidas cabíveis, sejam elas
                administrativas ou judiciais, se necessário.
            </p>
            </dd>

            <dt>Quem são os profissionais especializados que prestam serviço para a Theia?;</dt>
            <dd>
            <p>
                Essa é uma boa pergunta. A Theia, como fornecedora de Plataforma para intermediação de serviços, possui
                tanto empresas parceiras e quanto autônomos como prestadores de serviços na Plataforma
                ("especialistas"), sendo que essas empresas parceiras podem ser individuais ou não.
            </p>
            <p>
                Ou seja, a Theia não possui funcionários próprios que realizem atendimentos com agendamento na
                Plataforma. Assim, não nos responsabilizamos por suas agendas, disponibilidades, bem como ingerências,
                opiniões ou indicações feitas pelos especialistas durante os atendimentos, uma vez que eles são
                independentes da Theia e estão submetidos às resoluções e normas de éticas e de conduta de seus
                respectivos conselhos de classe (se aplicável), além da legislação vigente. Os especialistas observarão
                às regras relacionadas ao sigilo profissional e à Política de Privacidade, em especial no tocante aos
                dados do usuário a que tiverem acesso, para o correto cumprimento dos Termos e da tutela de saúde.
            </p>
            <p>
                Destacamos que nos empenhamos para que os especialistas intermediados pela plataforma sejam os melhores
                dentro de suas especialidades, baseados em avaliações de critérios próprios da Theia, os quais passam
                por um processo de análise e seleção criterioso para cadastro na plataforma. Além disso, são submetidos
                a treinamentos específicos de conduta e pode haver monitoramento sobre a qualidade do atendimento
                prestado via questionários aos usuários após realização dos atendimentos.
            </p>
            <p>
                Caso você não goste do atendimento recebido de um especialista em específico, poderá solicitar a
                designação de outro especialista na mesma especialidade para os próximos atendimentos.
            </p>
            <p>
                Do nosso lado, nos comprometemos a manter um controle constante de quem presta serviços na Plataforma.
                Caso constatemos que determinado profissional deixou de atender os requisitos legais e regulatórios
                determinados por cada Conselho de classe, tomará as providências para informar ao respectivo Conselho
                para as medidas cabíveis. Caso o profissional deixe de cumprir quaisquer condições contratuais firmadas
                entre a Theia e especialista, a relação dos usuários com este profissional não será mais intermediada
                pela plataforma, além das medidas cabíveis serem tomadas, dentre elas a expulsão da plataforma.
            </p>
            <p>
                Por fim, lembramos que apesar de alguns profissionais na Plataforma estarem disponíveis para
                atendimento presencial e outros não, e apesar de sermos entusiastas das tecnologias (o que, afinal, é
                nosso foco), o atendimento <i>online</i>, muitas vezes, não substitui a prestação de serviços de saúde de forma
                presencial, que poderá ser recomendada pelos profissionais na Plataforma a qualquer tempo.
            </p>
            </dd>

            <dt>Quais as responsabilidades da Theia?;</dt>
            <dd>
            <p>
                Agimos com transparência e respeitando as legislações vigentes, então, é muito pertinente esclarecermos
                quais são nossas responsabilidades, e até onde elas vão, na prestação dos Serviços que faremos a você.
                Leia com atenção, por favor, pois provavelmente esta seja uma das partes mais importante dos Termos (e
                você deve aceitá-los por completo para poder utilizar nossa Plataforma e Serviços).
            </p>
            <p>
                Sendo o mais direto possível, dentro dos limites da lei, e falando de maneira geral, nós não nos
                responsabilizados (expressas ou implicitamente), e não damos nenhuma garantia quanto à utilização da
                Plataforma ou de nossos Serviços, independente das ocorrências decorrerem em decorrência direta ou
                indireta do uso deles. Ou seja, a Theia, incluindo seus sócios e colaboradores, não se responsabilizam
                por quaisquer danos, incluindo, mas não se limitando a:
            </p>
            <ol>
                <li>Demoras, instabilidades, dificuldade de acesso ou incapacidade de utilização da Plataforma ou dos
                nossos Serviços;</li>
                <li>Vírus, falha de código, perda de dados ou problemas de funcionamento da Plataforma;</li>
                <li>Uso inadequado, perda de informações ou imprecisões de informações enviadas para a Plataforma;</li>
                <li>Quaisquer consequências ou danos causados pelos prestadores de serviço terceirizados
                (especialistas), bem como ingerências, opiniões ou indicações feitas durante os atendimentos;</li>
                <li>Descontinuidade de Serviços ou funcionalidades da Plataforma, parcialmente ou em sua totalidade;
                </li>
                <li>Suspensão ou cancelamento de contas de usuários em decorrência de suspeita ou comprovação de fraudes
                ou análogos, conforme pergunta oito;</li>
                <li>Perdas, despesas, danos diretos ou indiretos, em decorrência do desempenho ou uso da Plataforma.
                </li>
            </ol>
            <p>
                Por sermos uma empresa de tecnologia, de acordo com os princípios de boa-fé, ética e respeito aos Termos
                e a você, empregaremos os esforços cabíveis a fim de manter nossa Plataforma operante de forma
                ininterrupta. Entretanto, por circunstâncias que fogem ao nosso controle, a operação pode sofrer
                interrupções na acessibilidade e disponibilidade, bem como falhas ou bugs. Assim, a Theia não se
                responsabiliza por danos incorridos em razão de falhas ou interrupções no acesso à sua plataforma, bem
                como não indenizará ou ressarcirá usuários devido a eventuais falhas sob as quais não possuir
                responsabilidade.
            </p>
            <p>
                No caso descrito no item (v), a Theia poderá descontinuar funcionalidades, aplicações, aplicativos de
                celular, serviços, especialidades, de forma parcial ou total, sem que isso gere qualquer direito aos
                usuários. Entretanto, caso isso ocorra, os usuários (isso inclui você) serão comunicados
                antecipadamente.
            </p>
            </dd>

            <dt>Acesso e funcionalidades;</dt>
            <dd>
            <p>Após ler até aqui, você já deve então saber:</p>
            <ol>
                <li>O que é a Theia (que não é um plano de saúde);</li>
                <li>Quem presta serviços para nós;</li>
                <li>Quais as suas, e as nossas, responsabilidades;</li>
                <li>O que deve ser feito para se tornar nosso cliente (incluindo se enquadrar no nosso público-alvo);
                </li>
                <li>Que para acessar a Plataforma e nossos Serviços você deverá compreender e aceitar tudo o que estiver
                descrito nos Termos de Uso e na Política de Privacidade.</li>
            </ol>
            <p>
                Ótimo. Superados todos estes pontos, temos então o acesso à Plataforma. Podemos, por mera liberalidade,
                alterar os planos e condições da Plataforma a qualquer momento (vamos detalhar melhor no item 7). Então,
                ao se cadastrar, você deverá escolher entre os planos disponíveis, qual o que mais te atende, e deve
                aderi-lo. Você deve, obrigatoriamente, escolher um entre os planos disponíveis, pois os seus acessos
                futuros vão depender dessa escolha.
            </p>
            <p>
                Uma vez escolhido o plano (pode ser necessário que você insira seus dados de cartão de crédito para
                efetuar pagamento), você terá acesso às seguintes funcionalidades, mas não se limitando a (lembramos que
                podemos alterar as funcionalidades sem prejuízo e sem aviso prévio):
            </p>
            <ol>
                <li>Editar seu perfil e atualizar seus dados, podendo personalizar – mas não se limitando a – com
                informações pessoais, foto pessoal e dos filhos (caso haja), cônjuge (caso haja), assinatura e demais
                informações dos filhos;</li>
                <li>Escolher entre os planos disponíveis;</li>
                <li>Realizar o pagamento dos planos ou das consultas pontuais (se aplicável);</li>
                <li>Acessar os Termos de Uso e Política de Privacidade;</li>
                <li>Acessar conteúdos textuais exclusivos oferecidos pela Theia;</li>
                <li>Cancelar sua assinatura recorrente (se aplicável);</li>
                <li>Realizar e acompanhar o status dos agendamentos com especialistas;</li>
                <li>Realizar conversas com os especialistas nos dias e horários marcados via teleatendimento;</li>
                <li>Acessar os dados do perfil dos especialistas, onde constam a formação acadêmica e experiência
                profissional;</li>
            </ol>
            <p>
                Ressaltamos que algumas destas funcionalidades só estarão disponíveis mediante pagamento prévio (de
                forma recorrente ou pontual). No caso de oferecimento de planos atrelados a pagamento de mensalidades,
                os chamamos de <i>TheiaPass</i> (pois são passes de 30 dias corridos a partir da data de processamento do
                pagamento para utilização de Serviços específicos), e são assinaturas que podem ser feitas diretamente
                pelo usuário na Plataforma, com as formas e condições de pagamento e renovação disponibilizadas na
                mesma. Para ficar claro, cada plano ofertado pode ter um valor e acesso diferente, mas chamamos de
                &nbsp;<i>TheiaPass</i> a modalide de assinatura recorrente, ok?
            </p>
            <p>
                A Theia limita-se o direito de disponibilizar, a nosso único e exclusivo critério, atrelado a um plano
                específico ou não, conversas via chat com um time de triagem (que, se existente, terá sua
                disponibilidade definida na Plataforma, não sendo necessariamente um serviço disponibilizado em tempo
                integral). A atuação deste time de triagem é limitada exclusivamente à contato e identificação do
                usuário e não se confunde com os atendimentos prestados pelos especialistas (e, claramente, não é um
                time que realizará avaliações, diagnósticos e não emitirá documentos e nem fornecerá atestados, receitas
                ou informações fora de seu propósito de <u>contato e identificação do usuário</u>). Este time de triagem também
                poderá contatar você por outras plataformas ou formas de comunicação para que você acesse nossa
                Plataforma para dar continuidade aos seus atendimentos.
                Em relação aos atendimentos com os especialistas, você poderá realizar os agendamentos diretamente na
                Plataforma. Após a realização do atendimento, você poderá avaliar a qualidade do mesmo e a qualidade da
                plataforma – este <i>feedback</i> é muito importante para mantermos nossa intermediação sempre com os melhores
                especialistas, e para podermos melhorar ainda mais nossos Serviços e funcionalidades ofertados.
            </p>
            <p>
                Dependendo da modalidade de plano e da especialidade, poderá ser disponibilizada a possibilidade de
                atendimentos de forma assíncrona (ou seja, não é em tempo real), por mensagens, entre usuário e
                especialista, para os usuários que já tiverem realizado um atendimento com o mesmo especialista. Estas
                conversas, caso ocorram, devem ser realizadas para atendimentos que não sejam caracterizados como
                urgentes. O intuito do atendimento assíncrono é de esclarecer dúvidas dos usuários e orientá-los, não
                substituindo o atendimento em tempo real. O tempo de resposta dos especialistas pode variar, e não temos
                qualquer ingerência ou controle sobre o tempo de resposta, bem como sobre as orientações, opiniões ou
                informações passadas pelo especialista ao usuário.
            </p>
            <p>
                Claramente, dependo do Serviço ou funcionalidade que você queira adquirir na nossa Plataforma, é
                possível que estes estejam sujeitos, além dos presentes Termos e Política, a outros termos, diretrizes,
                políticas e acordos específicos, sejam eles termos da própria Theia ou de terceiros. Assim, pode ser que
                você tenha que ler e concordar com outro documento complementar para acessar determinado Serviço ou
                funcionalidade, ok?
            </p>
            <p>
                Para finalizar (sendo repetitivos, mas é importante), vamos dizer novamente o que consta na nossa
                primeira página: <b><u>situações de emergência ou urgência devem ser sempre encaminhadas ao serviço de
                pronto atendimento da respectiva cidade ou região do usuário, não sendo a plataforma Theia indicada
                para estas situações.</u></b>
            </p>
            </dd>

            <dt>Planos e formas de pagamento;</dt>
            <dd>
            <p>
                Todas as informações a respeito de planos de assinatura, serviços disponibilizados, formas de pagamento
                e opções de renovação (ou recorrência) encontram-se na Plataforma, e estes podem sofrer alterações sem
                aviso prévio, bem como podem ser diferentes para cada usuário, de acordo com critérios próprios da
                Theia. Também, podemos recusar uma ou mais formas de pagamento indicadas pelo usuário, caso haja
                inconsistência de informações, problemas com o emissor (na opção de pagamento com cartão de crédito),
                risco potencial de fraude ou mesmo se a plataforma de pagamentos utilizada não suportar alguma bandeira
                específica de cartões.

            </p>
            <p>
                A Theia reserva-se o direito de firmar parcerias corporativas com empresas a fim de permitir que seus
                colaboradores possam usufruir dos benefícios que a Theia oferece, mediante um aporte financeiro mensal
                por parte da empresa empregadora do usuário. Em tais parcerias, os usuários da empresa poderão aderir à
                Theia utilizando o valor do subsídio como parte do pagamento do valor mensal do pacote de acesso. Desta
                forma, como as condições comerciais variam a cada parceria, os valores dos planos disponíveis poderão
                variar entre usuários registrados em empresas parceiras diferentes.
            </p>
            <p>
                Já dissemos, mas não custa repetir: os serviços disponibilizados podem variar, mas estarão sempre
                disponíveis na Plataforma para consulta. Caso optemos por disponibilizar a execução de consultas avulsas
                (ou <i>"ad hoc"</i>), isto é, em que o cliente pague pontualmente por consulta, em adição ou não a um plano com
                pagamento mensal já contratado, nos reservamos o direito de realizar um bloqueio de valor (no valor da
                consulta) no seu cartão de crédito, que será cobrado apenas quando a consulta ocorrer. Assim, é possível
                fazermos uma gestão do horário do profissional que te atenderá, garantindo a reserva da agenda do mesmo.
                Caso você não possa comparecer (independentemente de ser presencial ou virtual), deverá cancelar com
                antecedência (a ser informada na Plataforma) pois, caso não cancele dentro do tempo pré-estipulado,
                podemos cobrar um valor de multa pelo não cumprimento do acordo (também a ser detalhado na Plataforma),
                sendo este uma fração do valor da consulta. O intuito não é punir você nem ninguém, pois entendemos que
                imprevistos acontecem, mas não seria ético nem justo com o profissional que disponibilizou a agenda,
                deixou de atender outras pessoas, e no fim não realizou sua consulta, certo?
            </p>
            <p>
                Nos planos pagos, o usuário poderá cancelar a assinatura do plano de acesso a qualquer momento,
                permanecendo o acesso como válido até a data de expiração do <i>TheiaPass</i>. Não serão devolvidos os valores
                proporcionais ao período faltante para expiração do prazo pré-determinado do <i>TheiaPass</i>. Caso haja a
                opção de Plano Anual ou Plano de Pacote Fechado, em que o usuário concorde em pagar um valor referente a
                um período de acesso (um ano, por exemplo), pago em parcelas mensais iguais, e o usuário decida cancelar
                a assinatura antes do término do prazo de pagamento acordado, podemos cobrar multa proporcional ao
                período faltante para terminar o contrato.
            </p>
            <p>
                Paralelamente, caso haja a disponibilidade de mais de um plano de <i>TheiaPass</i> para aquisição, e o usuário
                decidir por trocar seu plano de acesso, poderá fazê-lo desde que pague a diferença do valor entre os
                planos de acesso, caso o novo acesso tenha valor maior. As alterações de acesso serão realizadas em até
                24 (vinte e quatro) horas do processamento do pagamento. No caso de o usuário decidir por trocar seu
                plano por um de valor inferior, a alteração será efetivada no ato da renovação do <i>TheiaPass</i>.
            </p>
            <p>
                Como descrevemos na primeira página dos Termos, quando falamos "Theia", como empresa, podemos estar nos
                referindo a algumas empresas vinculadas, afiliadas. Assim, a cobrança para a qual você irá realizar seus
                pagamentos poderá ser emitida por essas empresas parceiras, por critério único e exclusivo da Theia.
                Entretanto, todas as <u>cobranças serão originadas na Plataforma Theia</u>, então desconfie e não aceite
                cobranças realizadas/advindas de fora da Plataforma, ok? Qualquer dúvida, envie um <i>e-mail</i> para
                suporte@theia.com.br. Nos comunique caso algum prestador queira/exija pagamentos por fora da Plataforma,
                e nos envie evidência da cobrança.
            </p>
            <p>
                Também, nos reservamos o direito de, por mera liberalidade, desenvolver campanhas, aplicar eventuais
                promoções, descontos ou períodos de teste para um grupo específico de usuários ou para todos os usuários
                da plataforma.
            </p>
            <p>
                Por fim, da mesma forma que as cobranças poderão ocorrer por empresas parceiras, as Notas Fiscais de
                Serviços a serem emitidas também poderão variar conforme a empresa que prestou o serviço para você.
                Assim, ao aceitar os Termos, você concorda que poderá receber Notas Fiscais emitidas pela Theia ou por
                terceiros, a exemplo dos próprios prestadores de serviços da Plataforma que te atenderam. Mas, falando
                das Notas Fiscais, serão emitidas em até 15 (quinze) dias corridos da realização da consulta e
                comprovação de pagamento, contendo o descritivo do atendimento realizado, nos exatos valores pagos por
                você.
            </p>
            </dd>

            <dt>Como funciona a exclusão de uma conta?;</dt>
            <dd>
            <p>
                Há, basicamente, três formas de uma conta ser excluída, sendo uma por iniciativa do usuário, e duas por
                iniciativa da Theia. Apesar de removermos seu acesso, lembramos que, conforme descrito na Política de
                Privacidade, a destruição ou anonimização dos seus dados pode não ser imediata, dado que há um prazo
                legal que devemos cumprir. Recomendamos a leitura (novamente) da Política caso reste alguma dúvida!
            </p>
            <p>
                Das formas citadas de exclusão, a primeira, naturalmente, é a mais simples de entender: é possível,
                infelizmente, que você queira, alguma hora, solicitar a exclusão da sua conta. Para tal, você pode
                enviar um <i>e-mail</i> para suporte@theia.com.br. Para nós, seria muito importante que você nos enviasse,
                neste <i>e-mail</i>, os dados da sua conta, comprovando que ela realmente pertence a você, e, também, um
                <i>feedback</i> nos dizendo o motivo do encerramento. Assim poderemos trabalhar para corrigir eventuais falhas
                para melhorar ainda mais a experiência de outros usuários.
            </p>
            <p>
                A segunda forma é em relação à inativi’dade. Também, conforme descrito na Política, temos algumas
                responsabilidades referente ao tratamento de dados. Assim, caso você fique um tempo sem acessar sua
                conta, podemos, unicamente a nosso critério, excluí-la e destruir (ou anonimizar) as informações as
                quais sejam possíveis te identificar – o que acaba sendo uma segurança tanto para você quanto para nós.
                Mas, não se preocupe, realizaremos as comunicações que forem necessárias para te avisar antes da
                exclusão, ok? Assim, caso você não queira que sua conta seja excluída, será possível reverter a situação
                neste prazo.
            </p>
            <p>
                A terceira forma é a que menos gostamos, e esperamos muito que não seja necessário utilizá-la: caso a
                Theia venha a constatar, por parte do usuário, qualquer tentativa de violação de propriedade intelectual
                ou mau uso da Plataforma, bem como comprovar tentativas de assédio, discriminação ou falta de respeito a
                qualquer pessoa, sendo prestadora de serviços (direta ou indiretamente) ou não da Theia. Não toleramos
                tentativas de fraudes, corrupção, assédio, abuso, má-fé ou discriminação. Caso você já tenha pago
                valores à Theia antecipadamente e se enquadre neste item, não realizaremos a devolução dos valores, e
                você eventualmente será responsabilizado pelos seus atos, e deverá arcar com a indenização e o
                ressarcimento dos danos causados à Theia ou a terceiros. Neste caso, provavelmente teremos de manter
                seus dados pessoais por mais tempo, mas sempre até o limite que seja necessário por lei.
            </p>
            <p>
                Por fim, nos dois primeiros casos, caso você queira voltar a se cadastrar na Plataforma e utilizar
                nossos Serviços, poderá fazê-los mediante criação de nova conta (não será possível "reativar" a conta
                excluída).
            </p>
            </dd>

            <dt>Propriedade intelectual;</dt>
            <dd>
            <p>
                Aqui trataremos e um tópico muito importante (a ponto de julgarmos valer um item específico para ele),
                que é a Propriedade intelectual. Entende-se por "propriedade intelectual" qualquer direito de patente,
                direito autoral, direito autoral moral, direito de marca, direitos sobre nome comercial, direito às
                marcas de serviços, direitos de imagem comercial, direitos sobre segredos comerciais; direitos sobre
                logos, designs, slogans e outros intangíveis genéricos de natureza assemelhada softwares de
                computadores, programas e aplicações, informações exclusivas tangíveis e intangíveis, <i>know-how</i>,
                processos exclusivos, fórmulas, algoritmos, direitos de propriedade, direitos de privacidade e direitos
                de publicidade, tendo sido tais direitos submetidos, registrados ou não sob a proteção de qualquer lei
                ou que estejam protegidos ou protegíveis sob a lei aplicável, internacionais ou nacionais.
            </p>
            <p>
                Ao aceitar estes Termos, você também concorda que os Termos não implicam qualquer licença, empréstimo
                para uso ou cessão de propriedade de equipamentos, <i>hardwares</i> ou <i>softwares</i> à você, não sendo permitida a
                exploração, cópia, reprodução (total ou parcial), ou divulgação com intuito comercial, sob pena das
                sanções previstas na lei, ou seja, o que é propriedade intelectual da Theia é da Theia, e aceitar estes
                Termos e utilizar nossos Serviços e Plataforma não dá nenhum direito ao usuário a respeito da nossa
                propriedade intelectual.
            </p>
            <p>
                Por "marca", compreende-se tudo que envolve o logotipo, o emblema e o nome de Theia. A utilização da
                marca Theia somente é legítima com autorização prévia formal por escrito emitida por Theia, devendo o
                pedido de autorização ser acompanhado de descrição do projeto em que se pretende o uso da marca. Caso
                não seja autorizado o uso, Theia se reserva o direito de exigir que a marca seja removida imediatamente
                de qualquer mídia (<i>online</i> ou impressa).
            </p>
            <p>
                A criação de links para a plataforma é autorizada, desde que o <i>link</i> esteja atribuído à Theia e não
                indique apoio ou patrocínio da Theia para projeto que não foi autorizado. O conteúdo que está o
                <i>hiperlink</i> não pode ser utilizado, integral ou parcialmente, nem ser incorporado material protegido por
                direitos autorais ou de propriedade intelectual da Theia.
            </p>
            </dd>

            <dt>Proteção de Dados Pessoais;</dt>
            <dd>
            <p>
                A proteção de dados pessoais é um assunto importantíssimo pois, afinal, estamos falando dos <b>seus</b>
                &nbsp;dados, os quais são primordiais para podermos prestar nossos Serviços a você da melhor forma
                possível, incluindo aqui os princípios éticos, morais e legais, além da experiência em si. Então,
                faremos nossos melhores esforços, sempre em concordância com a legislação vigente – em especial a Lei
                13.709/2018, também conhecida como Lei Geral de Proteção de Dados ("LGPD") – para manter seus dados
                pessoais plenamente seguros, e nos comprometemos a utilizá-los apenas nas finalidades e circunstâncias
                previstas na nossa Política de Privacidade. Falando nela (que também é condição necessária para poder
                utilizar nossos serviços), você pode acessá-la clicando&nbsp;
                <a
                href="https://theia.com.br/policy"
                target="_blank"
                className="cookies-link"
                >aqui</a>.
            </p>
            </dd>

            <dt>O que a Theia vai fazer quando quiser alterar ou atualizar estes Termos?;</dt>
            <dd>
            <p>
                Tudo muda na vida, e, eventualmente, nossos Termos também podem mudar. Caso haja alterações ou
                atualizações neles, elas estarão sempre disponíveis neste mesmo link que você acessou para chegar aqui,
                e você poderá, a qualquer hora, confirmar a data da última atualização sempre na primeira página, logo
                abaixo do título. Não se preocupe, avisaremos você caso haja alterações nos Termos, por meio dos nossos
                canais eletrônicos (<i>e-mail</i> ou notificação dentro da Plataforma). Lembrando que você só deverá utilizar
                ou continuar utilizando a Plataforma caso esteja de acordo com a plenitude dos Termos e da Política, ok?
            </p>
            </dd>

            <dt>O que mais?;</dt>
            <dd>
            <p>
                Bom, acreditamos que já tenhamos esclarecido quase tudo, mas ainda temos alguns pontos – um pouco mais
                técnicos - que gostaríamos de ressaltar, para não restar dúvidas. O primeiro, é que estes Termos (e a
                Política também) são regidos pelas leis da República Federativa do Brasil, ou seja, nos baseamos na
                legislação para brasileira para elaborá-los.
            </p>
            <p>
                O segundo ponto é que caso qualquer item ou apontamento destes Termos (ou da Política) se tornarem
                inválidos ou inexequíveis (não ser mais possível realizá-los por qualquer motivo), os demais itens ou
                apontamentos dos Termos (ou da Política) permanecem válidos (ou seja, os Termos ou Política continuam
                válidos no que for possível).
            </p>
            <p>
                Por fim, o terceiro e último ponto, é que colocaremos nossos melhores esforços para sua experiência com
                a Theia ser duradoura e a melhor possível mas, infelizmente, no caso de qualquer controvérsia que possa
                surgir e que não consigamos resolver de forma amigável, concordamos em eleger o foro da Comarca de São
                Paulo, com exclusão de qualquer outro, por mais privilegiado que seja, para podermos sanar as
                controvérsias.
            </p>
            </dd>

            <dt>E se, mesmo assim, você continuar com dúvidas?;</dt>
            <dd>
            <p>
                Caso tenha quaisquer dúvidas, reclamações ou elogios a respeito do nosso trabalho ou dos presentes
                Termos, não hesite em mandar um <i>e-mail</i> para o endereço ola@theia.com.br.
            </p>
            <p>
                Ressaltamos que, caso você tenha dúvidas a respeito de alguma parte dos Termos ou da Política, não
                recomendamos que você utilize nossos Serviços até que as dúvidas sejam esclarecidas, e que você passe a
                concordar com a totalidade do escrito nos Termos e na Política. Pois, ao aceitá-los, você consente que
                leu, compreendeu e concordou inequivocamente com absolutamente tudo descrito nos Termos e na Política
                (estamos sendo repetitivos para não restar dúvidas). Por fim, esperamos de verdade que estes Termos
                tenham sido esclarecedores, e que sua experiência com a Theia seja incrível!
            </p>
          </dd>
        </dl>
      </div>
    </Layout>
  )
}

export default Terms